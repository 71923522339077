<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <ProductTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="entity.gender_id"
                                    label="Пол"
                                    :items="genders"
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="entity.title"
                                    outlined
                                    label="Название"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="$route.params.id">
                            <v-col cols="12">
                                <v-text-field
                                    v-model="entity.code"
                                    outlined
                                    label="Артикул"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :value="formatedPrice"
                                    @input="setPrice"
                                    outlined
                                    label="Цена"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col xs="12" sm="6" md="4" lg="3" class="d-flex flex-column justify-space-between">
                                <span class="mb-4">
                                    <v-img v-if="entity.picture" :src="imageSrc(entity.picture)" />
                                </span>
                                <span>
                                    <v-file-input
                                        label="Основная картинка"
                                        outlined
                                        prepend-icon="mdi-image"
                                        hide-details
                                        @change="setMainPicture"
                                    />
                                </span>
                            </v-col>
                        </v-row>
                        <div class="elevation-1 pa-4 my-4">
                            <v-row>
                                <v-col class="d-flex justify-space-between align-center">
                                    <span><b>Дополнительные картинки</b></span>
                                    <v-btn color="primary" @click="addPicture">
                                        <v-icon>mdi-plus</v-icon> Добавить картинку
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <template v-for="(picture, index) in entity.pictures">
                                    <v-col :key="`picture-${index}`" xs="12" sm="6" md="4" lg="3" class="d-flex flex-column justify-space-between">
                                        <span class="mb-4">
                                            <v-img :src="imageSrc(picture)" />
                                        </span>
                                        <span class="d-flex justify-space-between align-center">
                                            <v-file-input
                                                label="Картинка"
                                                outlined
                                                prepend-icon="mdi-image"
                                                hide-details
                                                @change="setPicture($event, index)"
                                            />
                                            <v-icon color="error" class="ml-4" @click="removePicture(index)">mdi-trash-can</v-icon>
                                        </span>
                                    </v-col>
                                </template>
                            </v-row>
                        </div>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="entity.description"
                                    outlined
                                    label="Описание"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="entity.composition"
                                    outlined
                                    label="Состав"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-switch
                                    v-model="entity.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="entity.position"
                                    outlined
                                    label="Сортировка"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                    <v-btn
                        v-if="isAdmin && $route.params.id"
                        color="error"
                        @click="deleteModal = true"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>


        <v-dialog
            v-model="deleteModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="error">
                    Подтверждение
                </v-card-title>

                <v-card-text>
                    Вы действительно хотите удалить пользователя? Это действие нельзя будет отменить.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="error"
                        @click="remove"
                    >Удалить</v-btn>
                    <v-spacer />
                    <v-btn
                        color="light"
                        @click="deleteModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import ProductTabLinks from '@/components/products/ProductTabLinks';

    import { imageSrc, priceFormated, translit } from '../../helpers';

    export default {
        name: 'ProductEdit',

        components: {
            ProductTabLinks,
        },

        data() {
            return {
                imageSrc,
                priceFormated,
                entity: {
                    code: '',
                    title: '',
                    gender_id: null,
                    picture: '',
                    pictures: [],
                    description: '',
                    composition: '',
                    price: 0,
                    position: 0,
                    enabled: true,
                },
                deleteModal: false,
                formatedPrice: 0
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('genders', {
                genders: state => state.entities
            }),
            ...mapState('products', {
                oldEntity: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            const id = this.$route.params.id;
            await store.dispatch('genders/fetch');
            if(id) {
                await store.dispatch('products/get', {id});
                this.entity = this.oldEntity;
                this.formatedPrice = this.entity.price / 100; 
                if(!this.entity.pictures) {
                    this.entity.pictures = [];
                }
            }
        },

        methods: {
            // setTitle(title) {
            //     if(!this.entity.code) {
            //         this.entity.code = translit(title);
            //     }
            // },
            async setMainPicture(file) {
                this.entity.picture = await store.dispatch('files/upload', { file });
            },
            addPicture() {
                this.entity.pictures.push('');
            },
            removePicture(index) {
                this.entity.pictures.splice(index, 1);
            },
            async setPicture(file, index) {
                this.entity.pictures[index] = await store.dispatch('files/upload', { file });
                this.entity.pictures.push('');
                this.entity.pictures.splice(-1);
            },
            setPrice(price) {
                this.formatedPrice = price;
                this.entity.price = price * 100; 
            },
            async save() {
                try {
                    store.commit('products/SET_ENTITY', this.entity);
                    const {id} = await store.dispatch('products/save');
                    this.$router.push(`/products/${id}/detail`);
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            async remove() {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('products/delete', {id});
                    this.$router.push('/products');
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/products/${id}/detail`);
                } else {
                    this.$router.push('/products');
                }
            },
        },
    };
</script>
